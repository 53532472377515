import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import en_us from '../locales/en_us/translations.json';


i18n
  .use(Backend)
  .use(initReactI18next).init({
    debug: false,
    defaultNS: 'translations',
    lng: 'en_us',
    fallbackLng: 'en_us',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    resources: {
      en_us,
    },
  });

export default i18n;
