/* eslint-disable no-restricted-globals */
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './i18n';

export function getSubdomain() {
  return typeof location !== 'undefined' ? location.hostname.split('.')[0] : null;
}

export function getParams() {
  if (typeof location === 'undefined') {
    return {};
  }

  const url = location.href;
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function translatedConnect(component, mapStateToProps = null, mapDispatchToProps = null) {
  const translated = withTranslation('translations')(component);
  return connect(mapStateToProps, mapDispatchToProps)(translated);
}

export function getActiveEnvironment() {
  return process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
}
