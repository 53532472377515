import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Layout.module.css';
import '../../styles/base.css';


const Layout = ({ children }) => (
  <div className={cx(styles.root)}>
    <main>{children}</main>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
